import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
} from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Image } from "assets/img";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { Avatar } from "components/RFQ/Avatar";
import { Button } from "components/RFQ/Button";
import {
	DropdownItem,
	DropdownMenu,
	DropdownMenuDrawer,
	DropdownMenuTrigger,
} from "components/RFQ/MenuDropdown";
import { Form, Formik } from "formik";
import { getDateRelation } from "helpers/dateHelper";
import moment from "moment";
import { useContext, useRef, useState } from "react";
import { useCurrentCompany, useCurrentUser } from "reducers/store-helpers/auth";
import {
	createAddendumServices,
	deleteAddendumServices,
	getAddendumServices as getAddendumServicesGuest,
} from "services/guest-flow/addendumServices";
import {
	createQuestionServices,
	deleteAddendumQuestionServices,
	getAddendumServices,
} from "services/supplier-flow/adendumServices";
import tw, { styled } from "twin.macro";
import { cn, showToast } from "utils/helpers";
import {
	AddendumUserName,
	AnswerComp,
	ChatBubbleBox,
	StatusComp,
} from "../../../features/addendum/components/Addendum.elements";
import { AddendumLoading } from "../addendum/AddendumLoading";
import { FileUpload } from "components/RFQ/FileUpload";

import { AddendumAttachment } from "features/addendum/components/AddendumAttachment";
import { RFQContext } from "context/RFQContext";
import useRefreshWarning from "hooks/useRefreshWarning";
import { AcceptedFileTypesForRFxWithImage } from "utils/rfxLargeFileUploadHelper";

const AddendumsComp = ({
	rfqId,
	addendumData,
	isGuest,
	guestEmail,
	isCommunication,
	isDataLoading,
	setAddendumData,
	company,
	disabled,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const currentCompany = useCurrentCompany();
	const [selectedAddendum, setSelectedAddendum] = useState();
	const formikRef = useRef(null);
	const ContainerLogo = styled.div(() => [
		tw`relative w-[480px] h-[48px] text-center items-center flex`,
	]);
	const initialValues = {
		addendum: "",
		attachments: [],
	};
	const [openDialogDelete, setOpenDialogDelete] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [isSendingQuestion, setIsSendingQuestion] = useState(false);
	const [addendum, setAdendum] = useState("");
	const [attachments, setAttachments] = useState("");
	const [hasAttachment, setHasAttachment] = useState(false);
	const { uploadInProgresses } = useContext(RFQContext);
	useRefreshWarning(uploadInProgresses?.rfxBidder?.length > 0);

	const getAddendum = async () => {
		let res = isGuest
			? await getAddendumServicesGuest(rfqId, guestEmail)
			: await getAddendumServices(rfqId);
		if (res.status === 200) {
			setIsLoading(false);
		}
	};

	const onCLoseDialogDelete = () => {
		setOpenDialogDelete(false);
	};

	const SplitComp = ({ label }) => {
		return (
			<div className="text-primary-tertiary-600 relative my-4 flex h-[20px] items-center justify-center overflow-hidden font-roboto text-[14px]">
				<div className="w-full border border-solid border-[#EAECF0]"></div>
				<div className="absolute bg-white p-4">
					{getDateRelation(moment().format("YYYY-MM-DD"), label)}
				</div>
			</div>
		);
	};

	const AskComp = ({ question }) => {
		const currentUser = useCurrentUser();
		const isAuthor = question?.user?.name === currentUser?.full_name;
		return (
			<div className="relative">
				<div className="mt-2 flex items-start justify-start gap-3 pb-1">
					<Avatar
						size="md"
						avatar={question?.user?.logo_path}
						defaultImage={isAuthor ? "user" : "company"}
					/>
					<div style={{ width: "calc(100% - 52px)" }}>
						<AddendumUserName isCurrentUser={isAuthor}>
							{question?.user?.name}
						</AddendumUserName>
						<ChatBubbleBox isPublished={question?.published}>
							<div
								dangerouslySetInnerHTML={{
									__html: question?.addendum.replace("<span", "<p"),
								}}
								className="pb-2"
							/>
							{/* Attachments */}
							{question?.attachments?.map((attachment) => (
								<AddendumAttachment
									attachment={attachment}
									isCommunication={isCommunication}
								/>
							))}
						</ChatBubbleBox>
						<StatusComp
							isPublished={question?.published === 1}
							createdAt={question?.createdAt}
						></StatusComp>
					</div>
				</div>
				{question?.answer?.map((answ) => {
					return (
						<AnswerComp
							answer={answ}
							isCommunication={isCommunication}
						></AnswerComp>
					);
				})}
				{question?.company?.company_id === currentCompany?.id && (
					<div className="absolute -right-2 top-0">
						<DropdownMenu>
							<DropdownMenuTrigger
								icon
								noBg={true}
							></DropdownMenuTrigger>
							<DropdownMenuDrawer>
								<DropdownItem
									className="w-full"
									onClick={() => {
										setOpenDialogDelete(true);
										setSelectedAddendum(question);
									}}
								>
									<Icon
										icon="delete"
										width={12}
										height={12}
									/>
									<p
										className="font-roboto font-medium text-secondary-700"
										style={{ fontSize: "14px" }}
									>
										{"Delete Question"}
									</p>
								</DropdownItem>
							</DropdownMenuDrawer>
						</DropdownMenu>
					</div>
				)}
			</div>
		);
	};

	const onClose = () => {
		setOpenDialog(false);
	};

	const handleOutsideSubmit = async () => {
		setIsSendingQuestion(true);
		try {
			let res = isGuest
				? await createAddendumServices({
						addendum: addendum,
						rfqId: rfqId,
						attachments: attachments,
						email: guestEmail,
					})
				: await createQuestionServices({
						addendum: addendum,
						attachments: attachments,
						rfq_id: rfqId,
					});
			if (res.status === 200) {
				showToast(res?.data?.message, res?.data?.title, true);
				if (formikRef.current) {
					formikRef.current.resetForm();
				}
				setHasAttachment(false);
				setAddendumData(getUpdateAddendumList(res?.data?.data));
			} else {
				showToast(res?.data?.message, res?.data?.title);
			}
		} catch (err) {
			showToast(err?.response?.data?.message, "Error");
		}

		setOpenDialog(false);
		setIsSendingQuestion(false);
	};

	const getUpdateAddendumList = (newAddendum) => {
		if (addendumData.length === 0) {
			return [
				{
					date: moment(newAddendum.updatedAt).format("yyyy-MM-DD"),
					question: [newAddendum],
				},
			];
		}

		if (
			addendumData?.find(
				(addendum) =>
					addendum?.date === moment(newAddendum.updatedAt).format("yyyy-MM-DD")
			)
		) {
			return addendumData.map((addendum) => {
				if (
					addendum.date === moment(newAddendum.updatedAt).format("yyyy-MM-DD")
				) {
					addendum.question.unshift(newAddendum);
					return addendum;
				}

				return addendum;
			});
		}

		return [
			{
				date: moment(newAddendum.updatedAt).format("yyyy-MM-DD"),
				question: [newAddendum],
			},
			...addendumData,
		];
	};

	const handleSubmit = (values, { resetForm }) => {
		setAdendum(values.addendum);
		setAttachments(values.attachments);
		setOpenDialog(true);
	};

	const deleteQuestions = async () => {
		setIsDeleting(true);
		try {
			let res = isGuest
				? await deleteAddendumServices(rfqId, selectedAddendum.id, guestEmail)
				: await deleteAddendumQuestionServices(selectedAddendum.id);
			if (res.status === 200) {
				showToast(res?.data?.message, res?.data?.title, true);
				getAddendum();
				setSelectedAddendum();
				setOpenDialogDelete(false);
				setIsDeleting(false);
			} else {
				setIsDeleting(false);
			}
		} catch (err) {
			showToast(err?.response?.data?.message, "Error");
			setIsDeleting(false);
		}
	};

	return (
		<>
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				onSubmit={handleSubmit}
				style={{ width: "100%" }}
			>
				{({ values, isValid }) => (
					<Form>
						<div
							className={`overflow-hidden rounded-2xl ${addendumData?.length > 0 && "border border-solid border-[#EAECF0] shadow-sm"} bg-white pb-6`}
						>
							{isCommunication && (
								<div className="bg-gray-utility-100 p-10">
									<h5
										className="mb-5 font-roboto font-medium text-primary-900"
										style={{ fontSize: "14px" }}
									>
										Ask a question or seek clarification from the Client
									</h5>
									<div className="flex items-start justify-start">
										<Avatar
											avatar={company?.logo_path}
											noHover
										/>
										<div
											style={{ width: "100%" }}
											className="mb-4 ml-5"
										>
											<div className="relative">
												<FormatedInput
													name="addendum"
													type="text"
													value=""
													placeholder="Add a question"
													characterLimit={1500000}
													className="col xl-12"
													background="#fff"
													guideLine={
														"Tell us about this project and your company's role"
													}
													id="textnya"
													disabled={disabled}
												/>

												<div className="absolute bottom-0 left-0 mb-1 ml-1 mr-1 bg-white p-3">
													<div className="flex items-center justify-end">
														<button
															type="button"
															className="pointer"
															onClick={() => setHasAttachment(!hasAttachment)}
														>
															<Icon
																icon="paperclip"
																style={{ height: 18, width: 18 }}
															/>
														</button>
													</div>
												</div>
												<div className="absolute bottom-0 right-0 mb-1 ml-1 mr-1 bg-white p-3">
													<div className="flex items-center justify-end">
														<Icon icon="eye-slash" />
														<div className="ml-2 mr-10 font-roboto text-[12px] text-tertiary-600">
															Note: Your query will be visible to others only if
															the Client chooses to publish it as an addendum.
														</div>
														<Button
															btntype="primary"
															type="submit"
															disabled={
																values?.addendum?.trim() === "" ||
																!isValid ||
																isSendingQuestion ||
																uploadInProgresses?.rfxBidder?.length > 0
															}
														>
															{isSendingQuestion ? (
																<div className="flex items-center gap-3">
																	<Spinner /> Sending...{" "}
																</div>
															) : (
																"Send"
															)}
														</Button>
													</div>
												</div>
											</div>
											{hasAttachment && (
												<div>
													<FileUpload
														name="attachments"
														types={AcceptedFileTypesForRFxWithImage}
														fileExtraData={{
															model: "addendum",
														}}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							)}
							{isLoading || isDataLoading ? (
								<AddendumLoading />
							) : (
								<div className="px-8">
									{addendumData?.length > 0
										? addendumData.map((singleAddendumData) => {
												return (
													<>
														<SplitComp
															label={singleAddendumData?.date}
														></SplitComp>
														{singleAddendumData?.question.map(
															(question, index) => {
																return (
																	<AskComp
																		question={question}
																		key={index}
																	></AskComp>
																);
															}
														)}
													</>
												);
											})
										: !isCommunication && (
												<div className="text-center">
													<h1 className="text-text-bold whitespace-pre text-lg">
														No addendums have been published for this RFx yet.
													</h1>
													<p className="pt-1 text-sm">
														You’ll be notified via email if any addendums are
														published
													</p>
												</div>
											)}
								</div>
							)}
						</div>
						<div className="translate-y-1/2 overflow-hidden">
							<Modal
								isOpen={openDialog}
								onClose={onClose}
								size={"xl"}
								closeOnEsc
								isCentered
							>
								<ModalOverlay />
								<ModalContent containerProps={{ sx: { height: "100vh" } }}>
									<ModalHeader>
										<div className="overflow-hidden">
											<ContainerLogo>
												<Image
													img="background-pattern-02"
													className="absolute -left-5 -top-10 z-0"
													style={{ width: "180px" }}
												/>
												<div
													className={cn(
														"h-10 w-10 content-center items-center rounded-full",
														"bg-soft-blue"
													)}
													style={{ textAlign: "-webkit-center" }}
												>
													<Icon icon="blue-circle-check" />
												</div>
											</ContainerLogo>
											<h2 className="relative z-20 mt-4 text-lg font-semibold text-primary-900">
												Send Question
											</h2>
											<p className="mt-1 text-sm font-normal text-tertiary-600">
												You're about to submit your question. The Client may
												choose to make this query and answer visible to others
												as an addendum.
											</p>
										</div>
									</ModalHeader>
									<ModalCloseButton onClose={onClose} />
									<ModalFooter>
										<Flex
											gap={3}
											className="!w-full"
										>
											<Button
												btntype={"base"}
												style={{ width: "100%" }}
												onClick={onClose}
												type="button"
											>
												Cancel
											</Button>
											<Button
												style={{ width: "100%" }}
												onClick={handleOutsideSubmit}
												disabled={isSendingQuestion}
											>
												{isSendingQuestion ? (
													<div className="flex items-center gap-3">
														<Spinner /> Sending...{" "}
													</div>
												) : (
													"Yes, Send"
												)}
											</Button>
										</Flex>
									</ModalFooter>
								</ModalContent>
							</Modal>
						</div>

						<div className="translate-y-1/2 overflow-hidden">
							<Modal
								isOpen={openDialogDelete}
								onClose={onCLoseDialogDelete}
								size={"xl"}
								closeOnEsc
								isCentered
							>
								<ModalOverlay />
								<ModalContent containerProps={{ sx: { height: "100vh" } }}>
									<ModalHeader>
										<div className="overflow-hidden">
											<ContainerLogo>
												<Image
													img="background-pattern-02"
													className="absolute -left-5 -top-10 z-0"
													style={{ width: "180px" }}
												/>
												<div
													className={cn(
														"h-10 w-10 content-center items-center rounded-full",
														"bg-soft-red"
													)}
													style={{ textAlign: "-webkit-center" }}
												>
													<img
														src="/img/trash-icon.svg"
														alt="delete"
													/>
												</div>
											</ContainerLogo>
											<h2 className="relative z-20 mt-4 text-lg font-semibold text-primary-900">
												Delete Question
											</h2>
											<p className="mt-1 text-sm font-normal text-tertiary-600">
												You're about to delete your question. Please review your
												details carefully to ensure all your information is
												accurate and complete. Once confirmed, we will begin
												processing your delete request immediately.
											</p>
										</div>
									</ModalHeader>
									<ModalCloseButton onClose={onClose} />
									<ModalBody></ModalBody>
									<ModalFooter>
										<Flex
											gap={3}
											className="!w-full"
										>
											<Button
												btntype={"base"}
												style={{ width: "100%" }}
												onClick={onCLoseDialogDelete}
												type="button"
												disabled={isDeleting}
											>
												Cancel
											</Button>
											<Button
												danger
												style={{ width: "100%" }}
												onClick={() => deleteQuestions()}
												disabled={isDeleting}
											>
												{isDeleting ? <Spinner /> : "Yes, Delete it"}
											</Button>
										</Flex>
									</ModalFooter>
								</ModalContent>
							</Modal>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default AddendumsComp;
