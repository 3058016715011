import { navigate } from "@reach/router";
import { Button } from "components/RFQ/Button";
import { TabHeader } from "components/TabHeader";
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { ReactComponent as BackIcon } from "../../../../../assets/icons/flip-backward.svg";
import { ReactComponent as AddIcon } from "../../../../../assets/icons/close.svg";
import { Flex, SkeletonCircle, Spinner, useDisclosure } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import TabButton from "components/RFQ/TabButton/TabButton";
import { ProjectDetail } from "./ProjectDetail";
import { Documentation } from "./Documentation";
import {
	getDocumentType,
	getRFQId,
	getRFQType,
	getUnitMeasures,
	saveDraftRFQ,
} from "actions/RFQ/rfqsAction";
import { ReturnableSchedules } from "./ReturnableSchedules";
import { Form, Formik } from "formik";
import moment from "moment-timezone";
import { SkeletonBase } from "components/Skeleton";
import Quote from "./Quote/Quote";
import { Suppliers } from "./Suppliers";
import { PreviewRFQ } from "../PreviewRFQ";
import {
	formValuesChecker,
	tabLists,
	validationSchema,
	validStepChecker,
} from "./createRFQUtils";
import TextButton from "components/RFQ/Button/TextButton";
import SubmitRFQModal from "./SubmitRFQModal";
import { NotFound } from "routes/NotFound";
import { useSelector } from "react-redux";
import { showToast } from "utils/helpers";
import { RFQContext } from "context/RFQContext";
import useRefreshWarning from "hooks/useRefreshWarning";

const BackButton = ({ isNew }) => {
	return (
		<Button
			btntype={"icon"}
			onClick={() => navigate("..")}
			className={isNew && "!pointer-events-none"}
		>
			{isNew ? (
				<AddIcon
					style={{
						width: "25px",
						height: "25px",
						marginRight: "0px",
						rotate: "45deg",
					}}
				/>
			) : (
				<BackIcon
					style={{ width: "25px", height: "25px", marginRight: "0px" }}
				/>
			)}
		</Button>
	);
};

const CreateRFQPage = ({ rfqId, isEdit }) => {
	const [selectedTab, setSelectedTab] = useState(1);
	const [rfqData, setRFQData] = useState(null);
	const tabButtonList = tabLists;
	const [loadRFQData, setLoadRFQData] = useState(false);
	const formikRef = useRef();
	const [isSubmittingForm, setIsSubmittingForm] = useState(false);
	const [isValidForm, setIsValidForm] = useState(true);
	const [documentCategories, setDocumentCategories] = useState(null);
	const [rfqTypes, setRFQTypes] = useState(null);
	const [unitMeasures, setUnitMeasures] = useState(null);
	const [isNotFound, setIsNotFound] = useState(false);
	const [completedSteps, setCompletedSteps] = useState([]);
	const { company_id } = useSelector((state) => state?.auth?.user);
	const company = useSelector((state) => state?.auth?.current_company);
	const [validated, setValidated] = useState(false);
	const [validHandler, setValidHandler] = useState(null);
	const [touchedTabs, setTouchedTabs] = useState([]);

	const { onOpen, onClose, isOpen } = useDisclosure();

	const activeTab = tabButtonList.find((tab) => tab.value === selectedTab);
	const lastStep = selectedTab === 5;
	const [disableLastStep, setDisableLastStep] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const enableOnChangeFormik = selectedTab !== 3;

	const { setRFQId, uploadInProgresses } = useContext(RFQContext);
	const isFileUploading = uploadInProgresses?.rfxProcurer?.length > 0;

	useRefreshWarning(isFileUploading);

	const tabRef = useRef(null);

	useEffect(() => {
		setRFQId(rfqId);
		try {
			if (rfqId && !rfqData) {
				setLoadRFQData(true);
				getRFQId(rfqId).then((res) => {
					if (res) {
						setRFQData(res);
					} else {
						setIsNotFound(true);
					}
				});
			}
			getRFQType().then((res) => {
				setRFQTypes(
					res.map((type) => ({ label: type.name, value: type.code }))
				);
				getDocumentType().then((res) => {
					setDocumentCategories(
						res.map((doc) => ({ label: doc.name, value: doc.id }))
					);
					getUnitMeasures().then((res) => {
						setUnitMeasures(
							res.map((unit) => ({ label: unit.name, value: unit.id }))
						);
						setLoadRFQData(false);
					});
				});
			});
		} catch {
			setLoadRFQData(false);
			setIsNotFound(true);
			throw new Error("Failed when submit");
		}
		// eslint-disable-next-line
	}, []);

	const handleSave = useCallback(
		// additional message is outside the form
		async (additional_message, type) => {
			const values = formikRef?.current?.values;

			setValidated(false);
			setIsSubmittingForm(true);
			const submissionFormat = {
				...values,
				additional_message,
				quote: values?.quote_type !== "file" ? values?.quote : [],
				sections:
					values?.returnable_schedule_type === "form" ? values?.sections : [],
				location: values?.address?.value || "",
				location_latitude: values?.address?.latitude || "",
				location_longitude: values?.address?.longitude || "",
				state: values?.state || "",
				submission_deadline: values?.deadline_date
					? moment(
							`${moment(values?.deadline_date).format("YYYY-MM-DD")} ${values?.deadline_time}`,
							"YYYY-MM-DD HH:mm:ss"
						).format("YYYY-MM-DD HH:mm:ss")
					: "",
			};

			await saveDraftRFQ(
				rfqId,
				submissionFormat,
				selectedTab,
				lastStep && openModal
			)
				.then((res) => {
					if (res) {
						setRFQData(res);
						formikRef?.current?.setValues(formValuesChecker(res));
						setValidated(true);
						setIsSubmittingForm(false);
						setValidHandler(type);
					} else {
						formikRef?.current?.submitForm();
						lastStep && openModal && setTouchedTabs([1, 2, 3, 4, 5]);
						setOpenModal(false);
						setIsSubmittingForm(false);
						setValidated(false);
					}
				})
				.catch((e) => {
					setIsSubmittingForm(false);
				});
		},
		//eslint-disable-next-line
		[lastStep, openModal, rfqId, selectedTab, formikRef?.current?.values]
	);

	const handleSubmitOutside = async (type, additional_message) => {
		setValidHandler(null);
		if (type === "laststep") {
			setOpenModal(true);
		} else {
			checkIsCurrentStepValid(handleSave, additional_message, type);
		}
	};

	useEffect(() => {
		if (validated && validHandler) {
			switch (validHandler) {
				case "exit":
					navigate("..");
					setValidated(false);
					setValidHandler(null);
					break;
				case "next":
					setSelectedTab((prev) => prev + 1);
					setValidated(false);
					setValidHandler(null);
					break;
				case "submit":
					setValidated(false);
					setValidHandler(null);
					navigate("..");
					break;
				default:
					break;
			}
		}
	}, [validated, validHandler]);

	useEffect(() => {
		formikRef?.current?.validateForm();
		setTouchedTabs((prev) => {
			if (prev.includes(selectedTab)) {
				return prev;
			} else {
				return [...prev, selectedTab];
			}
		});
	}, [selectedTab]);

	useEffect(() => {
		if (rfqData) {
			if (rfqData?.status.toUpperCase() === "EVALUATION") {
				navigate(`/account/${company_id}/request-for-quote`);
			}
			if (!rfqData?.supplierList?.suppliers.length) {
				setDisableLastStep(true);
			}
			const formValues = formValuesChecker(rfqData);
			setCompletedSteps(
				[
					formValues?.type !== "" &&
					formValues?.address.value !== "" &&
					formValues?.deadline_date !== "" &&
					formValues?.deadline_time !== ""
						? 1
						: null,
					formValues?.documents.length > 0 ? 2 : null,
					formValues?.sections.filter((val) => val?.name !== "").length > 0
						? 3
						: formValues?.returnable_schedule_file
							? 3
							: null,
					formValues?.quote_type !== "file" &&
					formValues?.quote.filter((val) => val?.id !== null).length > 0
						? 4
						: formValues?.quote_file
							? 4
							: null,
					formValues?.guest.length > 0 || formValues?.suppliers.length > 0
						? 5
						: null,
				].filter((value) => value !== null)
			);
		}
	}, [rfqData, company_id]);

	const changeTab = (tab) => {
		// checkIsCurrentStepValid(setSelectedTab, tab.value);
		if (!isFileUploading) {
			setSelectedTab(tab.value);
		}
	};

	const checkIsCurrentStepValid = (handleOnStepValid, value, type) => {
		const currentForm = formikRef?.current;

		currentForm?.validateForm().then((res) => {
			currentForm?.setTouched(res);

			const isCurrentStepValid =
				validStepChecker(selectedTab, res) && touchedTabs.includes(selectedTab);

			if (isCurrentStepValid) {
				handleOnStepValid(value, type);
			} else {
				showToast("Please fill all required fields", "Error", false);
			}
		});
	};

	return isNotFound ? (
		<NotFound />
	) : (
		<div className="relative overflow-auto pt-4">
			<div ref={tabRef}>
				<TabHeader
					icon={<BackButton isNew={!isEdit} />}
					heading={
						isEdit ? `Edit  ${rfqData?.name || "RFx"}` : "CREATE YOUR RFx"
					}
					isLoading={loadRFQData && isEdit}
				>
					<Flex
						gap={3}
						align={"center"}
					>
						<TextButton
							disabled={isSubmittingForm || isFileUploading}
							onClick={() => handleSubmitOutside("submit")}
						>
							Save Draft
						</TextButton>
						<Button
							btntype={"secondary"}
							type="button"
							disabled={isSubmittingForm}
							onClick={onOpen}
						>
							<Flex
								gap={2}
								align={"center"}
							>
								<Icon icon={"view"} />
								Preview
							</Flex>
						</Button>
						<Button
							btntype={"primary"}
							onClick={() =>
								handleSubmitOutside(lastStep ? "laststep" : "next")
							}
							disabled={
								lastStep
									? !isValidForm || disableLastStep || isSubmittingForm
									: isSubmittingForm || isFileUploading
							}
						>
							{isSubmittingForm ? (
								<Spinner />
							) : lastStep ? (
								"Send RFx"
							) : (
								"Continue"
							)}
						</Button>
					</Flex>
				</TabHeader>
			</div>
			<div
				id="create-rfq-content"
				className="p-6"
			>
				{!loadRFQData ? (
					<div
						id="tab-button-rfq"
						className="mb-2 flex gap-4"
					>
						{tabButtonList?.map((tab, index) => (
							<TabButton
								key={index}
								{...tab}
								selectedTab={selectedTab}
								isActive={tab.value === selectedTab}
								onClick={() => changeTab(tab)}
								isFinished={
									completedSteps.includes(tab.value) &&
									validStepChecker(tab.value, formikRef?.current?.errors)
								}
								isError={
									!validStepChecker(tab.value, formikRef?.current?.errors) &&
									touchedTabs.includes(tab.value)
								}
							/>
						))}
					</div>
				) : (
					<div className="mb-2 flex w-full gap-4">
						{tabButtonList?.map((index) => (
							<div className="w-full space-y-4">
								<SkeletonBase
									height="8px"
									className="!w-full"
								/>
								<Flex className="items-center gap-3">
									<SkeletonCircle startColor="lightgray" />
									<SkeletonBase />
								</Flex>
							</div>
						))}
					</div>
				)}

				{!loadRFQData && rfqData ? (
					<Formik
						onSubmit={() => {}}
						initialValues={formValuesChecker(rfqData)}
						innerRef={formikRef}
						validationSchema={validationSchema()}
						validateOnChange={enableOnChangeFormik}
					>
						{({ isSubmitting, isValid, setFieldError, setFieldValue }) => {
							if (isValid !== isValidForm) {
								setIsValidForm(isValid);
							}
							return (
								<Form>
									{selectedTab === 1 && (
										<ProjectDetail
											titlePage={activeTab.title}
											subTitlePage={activeTab.subTitle}
											rfqData={rfqData}
											rfqTypes={rfqTypes}
										/>
									)}
									{selectedTab === 2 && (
										<Documentation
											titlePage={activeTab.title}
											subTitlePage={activeTab.subTitle}
											rfqData={rfqData}
											documentCategories={documentCategories}
											selectedTab={selectedTab}
										/>
									)}
									{selectedTab === 3 && (
										<ReturnableSchedules
											titlePage={activeTab.title}
											subTitlePage={activeTab.subTitle}
											rfqData={rfqData}
											setFieldError={setFieldError}
											setFieldValue={setFieldValue}
										/>
									)}
									{selectedTab === 4 && (
										<Quote
											titlePage={activeTab.title}
											subTitlePage={activeTab.subTitle}
											unitMeasures={unitMeasures}
											setFieldValue={setFieldValue}
										/>
									)}
									{lastStep && (
										<Suppliers
											rfqData={rfqData}
											showErrorMessage={false}
										/>
									)}
									<Flex
										gap={4}
										align={"center"}
										className="float-end py-10"
									>
										<TextButton
											disabled={isSubmittingForm || isFileUploading}
											onClick={() => handleSubmitOutside("save")}
										>
											Save Draft
										</TextButton>
										{selectedTab > 1 && (
											<Button
												disabled={isSubmittingForm || isFileUploading}
												btntype={"base"}
												type="button"
												onClick={() => {
													setSelectedTab((prev) => prev - 1);
													tabRef?.current?.scrollIntoView();
												}}
											>
												Back
											</Button>
										)}
										<Button
											disabled={
												lastStep
													? !isValid || disableLastStep || isSubmittingForm
													: isSubmittingForm || isFileUploading
											}
											btntype={"primary"}
											type="button"
											onClick={() =>
												handleSubmitOutside(lastStep ? "laststep" : "next")
											}
										>
											{isSubmittingForm ? (
												<Spinner />
											) : lastStep ? (
												"Send RFx"
											) : (
												"Continue"
											)}
										</Button>
									</Flex>
									{isOpen && (
										<PreviewRFQ
											isOpen={isOpen}
											onClose={onClose}
											rfqData={rfqData}
											unitMeasures={unitMeasures}
											documentCategories={documentCategories}
											rfqTypes={rfqTypes}
										/>
									)}
									<SubmitRFQModal
										isOpen={openModal}
										setIsOpen={setOpenModal}
										rfqId={rfqId}
										rfqDetail={rfqData}
										isSaving={isSubmittingForm}
										companyName={company?.name}
										isSubmitting={isSubmitting}
										handleSubmit={handleSubmitOutside}
									/>
								</Form>
							);
						}}
					</Formik>
				) : (
					<div className="h-[70vh]">
						<Spinner
							className="absolute left-1/2 top-[50vh] -translate-x-1/2"
							size="xl"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default CreateRFQPage;
