import React, { useEffect, useState } from "react";
import { Formik, Form, useFormikContext, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import debounce from "lodash.debounce";
import { Flex } from "@chakra-ui/core";
import { VStack, Divider, Grid, GridItem } from "@chakra-ui/react";
import { TextInput } from "../FormInputs/TextInput";
import { CompanySize } from "../FormInputs/CompanySize";
import { Sectors } from "../FormInputs/Sectors";
import { Speciality } from "components/FormInputs/Speciality";
import {
	submitCompanyInformationForm,
	getCompany,
	updateCompanyLogo,
} from "actions/companyActions";
import { getCurrentUser } from "actions/authActions";
import { Select } from "../FormInputs/Select";
import { ExpertiseRepeater } from "../FormInputs/ExpertiseRepeater";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Trades } from "../FormInputs/Trades";
import { GlobalPresenceMulti } from "../GlobalPresenceMulti/GlobalPresenceMulti";
import { TabContent } from "../Tabs/styled";
import { formatABN, isValidAbnOrAcn } from "../../utils/helpers";
import { FlashError } from "../FormInputs/FlashError";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import _ from "lodash";
import { Submit } from "components/FormInputs/Submit";
import { getGlobalPresence } from "actions/globalPresenceActions";
import { showToast as showToastFunction } from "../../utils/helpers";
import { ImageUpload } from "components/FormInputs/ImageUpload";

const AutoSave = ({ debounceMs }) => {
	const formik = useFormikContext();
	// eslint-disable-next-line
	const debouncedSubmit = React.useCallback(
		debounce(() => {
			if (formik.isSubmitting) return;
			if (!formik.isSubmitting) formik.submitForm();
		}, debounceMs),
		[formik.submitForm, formik.isSubmitting]
	);

	useEffect(() => {
		if (!formik.isSubmitting) {
			debouncedSubmit();
		} else {
			return () => debouncedSubmit.cancel();
		}
		return () => debouncedSubmit.cancel();
	}, [debouncedSubmit, formik.values, formik.isSubmitting]);
	return true;
};

export const CompanyInformationFormComponent = ({
	company,
	companyId,
	getCompany,
	getCurrentUser,
	btnCompanyInformation,
	getGlobalPresence,
	updateCompanyLogo,
	...props
}) => {
	const [initialValues, setInitialValues] = useState(props.initialValues);
	const [errorStatus, setErrorStatus] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [errorBusinesName, setErrorBusinesName] = useState({});
	const [isErrorBusinesName, setIsErrorBusinessName] = useState(false);
	const accountStatusOptions = [
		{ label: "Hidden", value: "hidden" },
		{ label: "Visible", value: "published" },
	];
	const [savedField, setSavedField] = useState(props.initialValues);
	const globalPresenceOptions = props.globalPresenceData.map((g) => {
		return { value: g.id, label: g.name };
	});

	useEffect(() => {
		if (
			JSON.stringify(savedField.logo) !== JSON.stringify(initialValues.logo)
		) {
			setInitialValues(savedField);
		}
		if (
			JSON.stringify(savedField.banner_image) !==
			JSON.stringify(initialValues.banner_image)
		) {
			setInitialValues(savedField);
		}
	}, [savedField, initialValues, setInitialValues]);

	useEffect(() => {
		if (props.globalPresenceData.length === 0) {
			getGlobalPresence();
		}
	}, [props.globalPresenceData, getGlobalPresence]);

	const validationSchema = Yup.object({
		name: Yup.string()
			.required()
			.max(80, "Business name should not exceed 80 characters"),
		status: Yup.string().when("profile_type", (profile_type, schema) => {
			if (profile_type !== 1) {
				return schema.required();
			}
			return schema;
		}),
		company_number: Yup.string(),
		sector_ids: Yup.array().min(1, "You must select at least one sector."),
		speciality_ids:
			company?.profile_type?.id === 3 || company?.profile_type?.id === 2
				? Yup.string().required()
				: "",
		description: Yup.string().required(),
		contact_name: Yup.string().required(),
		phone: Yup.string()
			.required("This is a required field")
			.matches(
				/^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/,
				"Invalid Phone No."
			),
		disciplines: Yup.array().when("profile_type", {
			is: (profile_type) => profile_type === 4,
			then: Yup.array().of(
				Yup.object().shape({
					expertise: Yup.string().required(),
					disciplines: Yup.array().required().min(1),
				})
			),
		}),
		abn: Yup.string()
			.required("This is a required field")
			.test(
				"test abn/acn",
				"Your ABN/ACN number is invalid",
				(value) => value && isValidAbnOrAcn(value)
			)
			.nullable(true),
		email: Yup.string().email().required(),
		website: Yup.string(),
		size_type_id:
			company?.profile_type?.id !== 1 ? Yup.string().required() : "",
		trade_ids: company?.profile_type?.id === 3 ? Yup.string().required() : "",
		global_presence:
			company?.profile_type?.id !== 1 ? Yup.array().required() : "",
		years_trading:
			company?.profile_type?.id !== 1 ? Yup.number().required() : "",
		logo: Yup.mixed().required(),
		banner_image: Yup.mixed().required(),
	});

	const handleSubmit = async (values, { setStatus }) => {
		const parsedValues = {
			...values,
			indigenous_buisness: values.indigenous_buisness === "true", // Convert string to boolean
		};

		try {
			const result = await props.submitCompanyInformationForm(
				parsedValues.company_id,
				parsedValues,
				setSavedField
			);

			if (result && result.data && result.data.logo_path) {
				updateCompanyLogo(result.data.logo_path);
			}

			showToast &&
				showToastFunction("Data updated successfully.", "Success", true);
			setIsErrorBusinessName(false);
			setErrorBusinesName({});
			setShowToast(false);
		} catch (e) {
			const error = await e.response?.json();
			setErrorStatus(true);
			setStatus(error?.message);
			if (error?.message === "The name has already been taken.") {
				setIsErrorBusinessName(true);
				setErrorBusinesName(error);
				//The name has already been taken.
			}
		}
	};

	return initialValues.name !== undefined ? (
		<Formik
			innerRef={props?.formikRef}
			enableReinitialize
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{(props) => {
				const {
					setFieldValue,
					isSubmitting,
					values,
					status,
					errors,
					setStatus,
					setErrors,
				} = props;
				const onSubmitValidationError = () => {
					setStatus("required");
					setErrorStatus(true);
				};
				const onMaxCharacter = () => {
					setStatus("maxCharacter");
					setErrorStatus(true);
				};

				//global presence values
				const globalPresenceInitialValues = initialValues.global_presence?.map(
					(g) => {
						return { value: g.id, label: g.name };
					}
				);

				let globalPresenceIds = values.global_presence?.map(
					(g) => g.id || g.value
				);
				let globalPresenceValueObj = globalPresenceOptions.filter((g) =>
					globalPresenceIds?.includes(g.value)
				);

				return (
					<Form onChange={() => setErrorStatus(false)}>
						{!isSubmitting &&
							!errorStatus &&
							!_.isEqual(savedField, values) && <AutoSave debounceMs={3000} />}
						<TabContent>
							<div style={{ marginLeft: "-46px", marginRight: "-46px" }}>
								<div className="grid grid-cols-2 gap-6">
									{company?.profile_type?.id !== 1 && (
										<div className="row">
											<Select
												label="Profile Visibility"
												name="status"
												required="true"
												className="col xl-6"
												setFieldValue={setFieldValue}
												options={accountStatusOptions}
												guideLine="Control when your profile is made visible or hidden to our members."
												testId={"profile-visibility"}
												value={accountStatusOptions.filter((option) => {
													return values.status === option.value;
												})}
											/>
										</div>
									)}
								</div>
								<VStack
									id="wrapper__company-details"
									spacing={2}
									align="stretch"
								>
									<Grid
										templateColumns="repeat(2, 1fr)"
										gap={2}
										columnGap={6}
									>
										<TextInput
											label="Company Name"
											testId="company-name-input"
											required="true"
											name="name"
											type="text"
										/>
										<TextInput
											label="ABN/ACN"
											testId="company-abn/acn-input"
											name="abn"
											type="text"
											formatValue={formatABN}
											required="true"
											setFieldValue={setFieldValue}
										/>
										<TextInput
											label="Website"
											testId="company-website-input"
											name="website"
											type="text"
										/>
										<Sectors
											label="Relevant Sectors / Experience"
											testId="company-sectors/experience"
											required="true"
											name="sector_ids"
											setFieldValue={setFieldValue}
											defaultValue={initialValues.sector_ids}
											values={values}
											sortingValue
										/>
										<GridItem colSpan={2}>
											<FormatedInput
												label="Company Description"
												subTitle="Share a brief overview of your business, including your vision, mission, and values. This description helps grasp the essence of your organisation and what sets you apart."
												required="true"
												name="description"
												characterLimit={1500}
												setErrors={setErrors}
												onMaxCharacter={onMaxCharacter}
											/>
										</GridItem>
										<GridItem colSpan={2}>
											<Flex
												justify="start"
												align="flex-start"
												className="flex flex-col items-center gap-3"
											>
												<div>
													<p className="-mt-1 font-roboto text-sm text-tertiary-600">
														Are you an Indigenous Business?
													</p>
												</div>

												<div
													className="-mt-1 flex space-x-4 font-roboto text-sm text-tertiary-600"
													role="group"
													aria-labelledby="my-radio-group"
												>
													<label className="cursor-pointer">
														<Field
															type="radio"
															data-testid="is-indigenous-business"
															name="indigenous_buisness"
															value="true"
														/>
														<span className="p-1">Yes</span>
													</label>
													<label className="cursor-pointer">
														<Field
															type="radio"
															data-testid="is-not-indigenous-business"
															name="indigenous_buisness"
															value="false"
														/>
														<span className="p-1">No</span>
													</label>
												</div>
											</Flex>
										</GridItem>
										<GridItem colSpan={2}>
											<Divider className="my-4" />
										</GridItem>
										{company?.profile_type?.id !== 1 && (
											<>
												<CompanySize
													name="size_type_id"
													testId="company-size-type-id"
													required={true}
													value={values.size_type_id}
													profileType={values.profile_type}
													editForm
													className="col-span-1"
													guideLine="This is the size of the business relevant to your State or Territory (include all other offices located within the same State or Territory)."
												/>
												<TextInput
													label="Number of Years Trading"
													testId="company-years-trading"
													required="true"
													name="years_trading"
													type="number"
													className="col-span-1"
													guideLine="Will be updated every year"
												/>
											</>
										)}
										<ImageUpload
											label="Company Logo"
											data-testid="company-logo-upload"
											name="logo"
											image={props.initialValues.logo || ""}
											setFieldValue={setFieldValue}
											className="flex-1"
											isFullHeight
											column
											uploadImage
											required
										/>
										<ImageUpload
											label="Banner Image"
											data-testid="company-banner-upload"
											name="banner_image"
											image={props.initialValues.banner_image}
											setFieldValue={setFieldValue}
											className="flex-1"
											isFullHeight
											column
											uploadImage
											guideLine="Recommended banner image resolution is 600x 400 pixels for optimal display quality."
											required
										/>
									</Grid>

									{company?.profile_type?.id !== 1 && (
										<GlobalPresenceMulti
											options={globalPresenceOptions}
											name="global_presence"
											testId="company-global-presence"
											setFieldValue={setFieldValue}
											label="Global Presence"
											required={true}
											defaultValue={globalPresenceInitialValues}
											values={globalPresenceValueObj}
											isClearable={true}
											className="col-span-1"
											sortingValue
										/>
									)}
									{company?.profile_type?.id === 4 && (
										<ExpertiseRepeater
											label="Services provided by company"
											testId="company-services"
											name="disciplines"
											values={values.disciplines}
											profileType={values.profile_type}
											required
										/>
									)}
									{(company?.profile_type?.id === 2 ||
										company?.profile_type?.id === 3) && (
										<Speciality
											label="Specialty"
											testId="company-specialty"
											required="true"
											name="speciality_ids"
											setFieldValue={setFieldValue}
											values={values}
											className="col-span-1"
										/>
									)}
									{/*  subcontractors */}
									{company?.profile_type?.id === 3 && (
										<Trades
											name="trade_ids"
											testId="company-trades"
											required
										/>
									)}
								</VStack>

								<VStack
									id="wrapper__contact-details"
									spacing={2}
									align="stretch"
								>
									<Grid
										templateColumns="repeat(2, 1fr)"
										gap={2}
										columnGap={6}
									>
										<TextInput
											label="Primary Contact Name"
											data-testid="company-primary-contact-name"
											required="true"
											name="contact_name"
											type="text"
											guideLine="This is the main point of contact for your account and profile."
										/>
										<TextInput
											label="Company Phone Number"
											testId="company-phone-number"
											required="true"
											name="phone"
											type="text"
											flexGrow="1"
										/>
										<TextInput
											label="Company Email Address"
											testId="company-email"
											required="true"
											name="email"
											type="text"
										/>
									</Grid>
								</VStack>
								<div className="row">
									<div className="col xl-8">
										{status && !_.isEmpty(errors) && (
											<FlashError
												heading={errorMessages[status]?.heading || status}
												text={errorMessages[status]?.description || status}
												margin="0px 0px 20px 0px"
											/>
										)}
										{isErrorBusinesName && (
											<>
												<FlashError
													heading={errorBusinesName.message}
													text="The name has already been taken."
													margin="0px 0px 20px 0px"
												/>
											</>
										)}
									</div>
								</div>
								<TextInput
									name="company_id"
									value={initialValues.company_id}
									type="hidden"
								/>
								<div className="mt-12 flex justify-end">
									<Submit
										id="btnCompanyInformation"
										type="submit"
										onClick={() => {
											setShowToast(true);
										}}
										isSubmitting={isSubmitting}
										spinner
										ref={btnCompanyInformation}
										text="Save Profile"
										submittingText="Saving..."
										disabled={!_.isEmpty(errors)}
										className="!h-[50px]"
										testId={"save-profile-button"}
									/>
								</div>
								<OnSubmitValidationError callback={onSubmitValidationError} />
							</div>
						</TabContent>
					</Form>
				);
			}}
		</Formik>
	) : (
		<Skeleton
			count={5}
			duration={0.5}
		/>
	);
};
const errorMessages = {
	required: {
		heading: "* Required Field",
		description: "There are incomplete required fields. Please complete them.",
	},
	connection: {
		heading: "Connection not established",
		description: "Unable to process your request",
	},
	maxCharacter: {
		heading: "Input has reached max character",
		description: "Input has reached max character",
	},
};

const mapStateToProps = (state) => {
	const company = state.search.activeCompany;
	const companyId = state.search.activeCompany.id;
	const globalPresenceData = state.globalPresence.data;

	return {
		company: company,
		companyId: companyId,
		globalPresenceData,
		initialValues: {
			logo: company?.logo_path,
			banner_image: company?.banner_path || "",
			name: company?.name || "",
			company_number: company?.company_number || "",
			sector_ids: Array.isArray(company?.sector)
				? company?.sector.map((sector) => sector.id)
				: [],
			speciality_ids: Array.isArray(company?.speciality)
				? company?.speciality.map((speciality) => speciality.id)
				: [],
			size_type_id: company?.size_type_id || "",
			description: company?.description || "",
			keywords: company?.keywords || [],
			services: company?.services || [],
			contact_name: company?.contact_name || "",
			phone: company?.phone || "",
			email: company?.email || "",
			website: company?.website || "",
			company_id: company?.id || "",
			status: company?.status === "draft" ? 1 : company?.status,
			disciplines: company?.disciplinesFormatted?.length
				? company?.disciplinesFormatted
				: [{ expertise: "", disciplines: [] }],
			profile_type: company?.profile_type?.id,
			trade_ids: Array.isArray(company?.trade)
				? company?.trade.map((trade) => trade.id)
				: [],
			years_trading: parseInt(company?.years_trading) || "",
			global_presence: company?.global_presence?.map((g) => {
				return {
					value: g.id,
					label: g.name,
				};
			}),
			abn: company?.abn,
			indigenous_buisness: company?.indigenous_buisness ? "true" : "false",
		},
	};
};

export const CompanyInformationForm = connect(mapStateToProps, {
	submitCompanyInformationForm,
	getCurrentUser,
	getCompany,
	getGlobalPresence,
	updateCompanyLogo,
})(CompanyInformationFormComponent);
