import { DateInput } from "components/DateInput";
import { Form, Formik } from "formik";
import TimePickerField from "components/TimePicker/TimePicker";
import { Button } from "components/RFQ/Button";

import * as Yup from "yup";
import { useRef, useState } from "react";
import { updateSubmissionDeadLine } from "actions/RFQ/rfqsAction";
import moment from "moment";
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	Spinner,
} from "@chakra-ui/react";
import { showToast } from "utils/helpers";
import { CloseButton } from "components/RFQ/CloseButton";
import EmailPreview from "pages/EmailPreview/EmailPreview";
import { Input } from "components/RFQ/Input";
import { isNull } from "utils/validation";

const ExtendSubmissionDeadlineModal = ({
	isModalOpen,
	rfqDetail,
	onSubmissionDateExtension,
	onClose,
	props,
}) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const formikRef = useRef();
	const [formValues, setFormValues] = useState();

	const onSubmit = (formObject) => {
		const submissionDeadline = formValues?.extended_time
			? moment(
					moment(formValues?.extended_date).format("YYYY-MM-DD") +
						" " +
						formValues?.extended_time +
						":00"
				).format("YYYY-MM-DD HH:mm:ss")
			: "";
		const data = {
			submission_deadline: submissionDeadline,
			submission_deadline_timezone: formValues.submission_deadline_timezone,
			additional_message: formValues?.additional_message,
		};

		setIsSubmitting(true);
		updateSubmissionDeadLine(rfqDetail.id, data)
			.then((res) => {
				onSubmissionDateExtension(res.data.data);
				setIsSubmitting(false);
				onClose();
				showToast("Submission Deadline Extended Successfully", "Success", true);
			})
			.catch((e) => {
				isSubmitting(false);
				onClose();
			});
	};

	return (
		<>
			<Drawer
				placement={"right"}
				isOpen={isModalOpen}
				onClose={onClose}
				size="xl"
				{...props}
				className="bg-white p-11"
			>
				<DrawerOverlay onClick={onClose} />
				<DrawerContent maxWidth={"1280px"}>
					<DrawerHeader>
						<Flex
							justifyContent="space-between"
							align={"center"}
						>
							<div>
								<p className="text-lg font-semibold text-primary-900">
									Submission Deadline Extension
								</p>
							</div>
							<CloseButton onClose={onClose} />
						</Flex>
					</DrawerHeader>
					<DrawerBody>
						<div className="grid grid-cols-3">
							<div className="relative">
								<div className="fixed">
									<div>
										<Formik
											innerRef={formikRef}
											validationSchema={Yup.object().shape({
												extended_date: Yup.string().required(
													"This field is required"
												),
												extended_time: Yup.string().required(
													"This field is required"
												),
											})}
											initialValues={{
												extended_date: rfqDetail?.submissionDeadline,
												extended_time: moment(
													rfqDetail?.submissionDeadline
												).format("HH:mm"),
												submission_deadline_timezone:
													rfqDetail?.submissionTimezone,
												additional_message: "",
											}}
											onSubmit={onSubmit}
										>
											{(props) => {
												const { setFieldValue, values } = props;
												setFormValues(values);
												return (
													<Form>
														<DateInput
															name="extended_date"
															label="Extended Deadline"
															setFieldValue={setFieldValue}
															values={values}
															minDate={moment(
																moment(rfqDetail?.submissionDeadline).format(
																	"YYYY/MM/D"
																)
															).toDate()}
														/>
														<TimePickerField
															className="mt-9 w-full flex-1"
															name="extended_time"
															nameTimezone="submission_deadline_timezone"
															label="Extended Time"
														/>
														<Input
															label="Additional Message"
															name="additional_message"
															type="textArea"
															placeholder="Enter additional message you want to send on mail"
															required
															error={
																isNull(values.additional_message) &&
																"Additional message is required"
															}
															onChange={(e) => {
																return setFieldValue(
																	"additional_message",
																	e.target.value
																);
															}}
														/>
														<Button
															btntype="submit"
															className="my-4 !w-full !py-3"
														>
															Extend Time
														</Button>
														{isSubmitting && (
															<div className="flex items-center justify-center gap-4 pt-4 text-royal-blue">
																<Spinner />
																<p className="text-center font-bold">
																	Submitting ....
																</p>
															</div>
														)}
													</Form>
												);
											}}
										</Formik>
									</div>
								</div>
							</div>
							<div className="col-span-2">
								<EmailPreview
									rfqTitle={rfqDetail?.name}
									projectTitle={rfqDetail?.project?.name}
									time={moment(
										moment(formValues?.extended_date).format("YYYY-MM-DD") +
											" " +
											formValues?.extended_time +
											":00"
									).format("hh:mm A")}
									date={moment(formValues?.extended_date).format("yyyy/MM/DD")}
									timeZone={formValues?.submission_deadline_timezone}
									isRFQInvitation={false}
									additionalMessage={formValues?.additional_message}
								/>
							</div>
						</div>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			{/* <CustomModal
				header={{
					icon: "calendar",
					bgColor: "bg-brand-secondary",
					title: `Extend submission deadline`,
				}}
				isOpen={isModalOpen}
				onClose={onClose}
			>
				<div>
					<Formik
						innerRef={formikRef}
						validationSchema={Yup.object().shape({
							extended_date: Yup.string().required("This field is required"),
							extended_time: Yup.string().required("This field is required"),
						})}
						initialValues={{
							extended_date: rfqDetail?.submissionDeadline,
							extended_time: moment(rfqDetail?.submissionDeadline).format(
								"HH:mm"
							),
							submission_deadline_timezone: rfqDetail?.submissionTimezone,
						}}
						onSubmit={onSubmit}
					>
						{(props) => {
							const { setFieldValue, values } = props;

							return (
								<Form>
									<DateInput
										name="extended_date"
										label="Extended Deadline"
										setFieldValue={setFieldValue}
										values={values}
										minDate={moment(
											moment(rfqDetail?.submissionDeadline).format("YYYY/MM/D")
										).toDate()}
									/>
									<TimePickerField
										className="mt-9 w-full flex-1"
										name="extended_time"
										nameTimezone="submission_deadline_timezone"
										label="Extended Time"
									/>
									<Button
										btntype="submit"
										className="!w-full !py-3"
									>
										Extend Time
									</Button>
									{isSubmitting && (
										<div className="flex items-center justify-center gap-4 pt-4 text-royal-blue">
											<Spinner />
											<p className="text-center font-bold">Submitting ....</p>
										</div>
									)}
								</Form>
							);
						}}
					</Formik>
				</div>
			</CustomModal> */}
		</>
	);
};

export default ExtendSubmissionDeadlineModal;
