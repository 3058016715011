import { EmailComponentType } from "./EmailComponents.type";

export const RFQInvitationEmailContent: React.FC<EmailComponentType> = ({
	companyName,
	rfqTitle,
	projectTitle,
	additionalMessage,
	date,
	time,
	timeZone,
}) => {
	return (
		<div style={{ background: "white" }}>
			<svg
				width="664"
				height="224"
				viewBox="0 0 664 224"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_30_11)">
					<mask
						id="mask0_30_11"
						style={{ maskType: "luminance" }}
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="664"
						height="224"
					>
						<path
							d="M0 16C0 7.16345 7.16344 0 16 0H648C656.837 0 664 7.16344 664 16V224H0V16Z"
							fill="white"
						/>
					</mask>
					<g mask="url(#mask0_30_11)">
						<path
							d="M0 16C0 7.16345 7.16344 0 16 0H648C656.837 0 664 7.16344 664 16V224H0V16Z"
							fill="#003CE9"
						/>
						<path
							d="M667.718 -241C680.248 -161.57 663.646 15.6318 497 89"
							stroke="#708FFB"
							stroke-width="1.5"
						/>
						<path
							d="M497 96C500.866 96 504 92.866 504 89C504 85.134 500.866 82 497 82C493.134 82 490 85.134 490 89C490 92.866 493.134 96 497 96Z"
							fill="#4864F9"
						/>
						<path
							d="M329.001 72.0001H318.001M317.832 72.5831L313.162 86.5325C312.795 87.6283 312.611 88.1763 312.743 88.5137C312.857 88.8067 313.103 89.0289 313.406 89.1135C313.755 89.2108 314.282 88.9737 315.336 88.4995L348.758 73.4592C349.787 72.9963 350.301 72.7648 350.46 72.4433C350.599 72.1639 350.599 71.8362 350.46 71.5569C350.301 71.2353 349.787 71.0039 348.758 70.541L315.324 55.4955C314.273 55.0227 313.748 54.7863 313.399 54.8833C313.097 54.9675 312.851 55.1891 312.736 55.4816C312.604 55.8183 312.786 56.3651 313.149 57.4586L317.833 71.5711C317.895 71.7589 317.926 71.8528 317.939 71.9489C317.95 72.0341 317.949 72.1204 317.938 72.2056C317.926 72.3016 317.894 72.3954 317.832 72.5831Z"
							stroke="#DEEBFF"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M-82 31L80 194"
							stroke="#708FFB"
							stroke-width="1.5"
						/>
						<path
							d="M80 201C83.866 201 87 197.866 87 194C87 190.134 83.866 187 80 187C76.134 187 73 190.134 73 194C73 197.866 76.134 201 80 201Z"
							fill="#4864F9"
						/>
						<path
							d="M226.468 164V118.4H239.788L243.388 122V140.96L240.208 144.14L243.808 164H238.228L234.688 144.56H232.228V164H226.468ZM236.428 139.88L237.628 138.68V124.4L236.428 123.2H232.228V139.88H236.428ZM260.284 118.4V123.32H252.004V138.98H259.444V143.9H252.004V164H246.244V118.4H260.284ZM272.782 140.78L279.562 164H273.802L269.782 149.24L265.942 164H260.422L267.202 140.78L260.722 118.4H266.482L270.142 132.68L273.742 118.4H279.262L272.782 140.78ZM288.757 164V118.4H294.517V164H288.757ZM297.284 164V118.4H303.524L310.124 150.02V118.4H315.344V164H309.104L302.504 132.86V164H297.284ZM329.935 164H321.895L316.675 118.4H322.315L326.035 157.22L329.755 118.4H335.155L329.935 164ZM336.485 164V118.4H342.245V164H336.485ZM360.252 123.2H354.792V164H349.032V123.2H343.572V118.4H360.252V123.2ZM369.318 152.24H364.038L362.898 164H357.138L362.598 118.4H370.878L376.458 164H370.458L369.318 152.24ZM368.898 147.32L366.678 124.34L364.458 147.32H368.898ZM390.02 123.2H384.56V164H378.8V123.2H373.34V118.4H390.02V123.2ZM391.334 164V118.4H397.094V164H391.334ZM416.9 122V160.4L413.3 164H403.28L399.68 160.4V122L403.28 118.4H413.3L416.9 122ZM409.94 123.32H406.64L405.44 124.52V157.88L406.64 159.08H409.94L411.14 157.88V124.52L409.94 123.32ZM419.461 164V118.4H425.701L432.301 150.02V118.4H437.521V164H431.281L424.681 132.86V164H419.461Z"
							fill="white"
						/>
					</g>
				</g>
				<defs>
					<clipPath id="clip0_30_11">
						<rect
							width="664"
							height="224"
							fill="white"
						/>
					</clipPath>
				</defs>
			</svg>
			<table
				cellPadding="0"
				cellSpacing="0"
				border={0}
				width="100%"
				style={{ backgroundColor: "#ffffff" }}
			>
				<tr>
					<td align="center">
						<table
							cellPadding="0"
							cellSpacing="0"
							border={0}
							width="600"
							style={{ maxWidth: "600px" }}
						>
							<tr>
								<td style={{ padding: "40px 32px" }}>
									<table
										cellPadding="0"
										cellSpacing="0"
										border={0}
										width="100%"
									>
										<tr>
											<td
												style={{
													fontFamily: "Arial, sans-serif",
													fontSize: "16px",
													lineHeight: "24px",
													color: "#1f2937",
													paddingBottom: "24px",
												}}
											>
												Dear [Recipient(s) Name],
											</td>
										</tr>
										<tr>
											<td
												style={{
													fontFamily: "Arial, sans-serif",
													fontSize: "16px",
													lineHeight: "24px",
													color: "#374151",
													paddingBottom: "20px",
												}}
											>
												Your expertise and experience could be a valuable
												contribution to this project. <b>{companyName}</b> has
												invited [Supplier Company Name] to participate in a{" "}
												<b>{rfqTitle}</b> for <b>{projectTitle}</b>.
											</td>
										</tr>
										<tr>
											<td
												style={{
													fontFamily: "Arial, sans-serif",
													fontSize: "16px",
													lineHeight: "24px",
													color: "#374151",
													paddingBottom: "20px",
												}}
											>
												Additional information from client:
												<br />
												<br />
												{additionalMessage}
											</td>
										</tr>
										<tr>
											<td
												style={{
													fontFamily: " Arial, sans-serif",
													fontSize: "16px",
													lineHeight: "24px",
													color: "#374151",
													paddingBottom: "20px",
												}}
											>
												You can preview the [RFQ Title] and accept or decline
												the invitation by clicking on the following link:{" "}
												<span
													style={{
														color: "#003ce9",
														textDecoration: "underline",
													}}
												>
													{rfqTitle}
												</span>{" "}
												by{" "}
												<b>
													{" "}
													{date} at {time} {timeZone}
												</b>
												.
											</td>
										</tr>
										<tr>
											<td
												style={{
													fontFamily: " Arial, sans-serif",
													fontSize: "16px",
													lineHeight: "24px",
													color: "#374151",
													paddingBottom: "20px",
												}}
											>
												We kindly request you review the details at your
												earliest convenience. Should you have any questions or
												require further information, feel free to contact me
												directly.
											</td>
										</tr>
										<tr>
											<td
												style={{
													fontFamily: " Arial, sans-serif",
													fontSize: "16px",
													lineHeight: "24px",
													color: "#374151",
													paddingBottom: "20px",
												}}
											>
												Thank you for considering this opportunity. We look
												forward to your response.
											</td>
										</tr>
										<tr>
											<td
												style={{
													fontFamily: " Arial, sans-serif",
													fontSize: "16px",
													lineHeight: "24px",
													color: "#374151",
												}}
											>
												Best regards,
												<br />
												<br />
												[contact name]
												<br />
												{rfqTitle} Lead
												<br />
												[contact email]
												<br />
												[contact number]
												<br />
												[company name]
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</td>
				</tr>
			</table>
		</div>
	);
};
