import React from "react";
import { Button } from "../../Button";
import { Heading } from "../../Heading";
import { Modal } from "../../Modal";
import { navigate } from "@reach/router";
import RestrictedDocumentImage from "./restricted-doc.png";
import { ReactComponent as CheckIcon } from "../../../icons/check-circle.svg";
import { RestrictedDocumentModalWrapper } from "./styled";

export const RestrictedDocumentModal = ({ isOpen, setIsOpen, guestEmail }) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={() => setIsOpen(false)}
			width="926"
			closeIconTop="24px"
			blurBackground
		>
			<RestrictedDocumentModalWrapper>
				<img
					src={RestrictedDocumentImage}
					alt="header-img"
				/>
				<div className="side-content" data-testid="upgrade-account-restriction">
					<Heading
						fontSize="48px"
						marginBottom="24px"
					>
						Become a member to view this document
					</Heading>
					<p>
						To gain access to these reports you will need to sign up to become a
						Procuracon member. Providing you acess to a variety of features.
					</p>
					<span>
						<CheckIcon /> Search compare & Shortlist suppliers instantly
					</span>
					<span>
						<CheckIcon /> Save time and money with our automated shortlist
						reports
					</span>

					<Button
						size="sm"
						type="button"
						width="204px"
						height="56px"
						textfont="14px"
						boxshadow={false}
						onClick={() =>
							navigate("/signup", {
								state: {
									email: guestEmail,
								},
							})
						}
					>
						UPGRADE ACCOUNT
					</Button>
				</div>
			</RestrictedDocumentModalWrapper>
		</Modal>
	);
};
